:root {
    --content-background: rgba(219, 209, 209, 0.98);
}

html {
    height: 100%;
}

body {
    min-height: 100vh;
}

body {
    font-family: 'Alata', sans-serif;
    background: #CCCCCC url("../img/background.jpg") fixed center center;
    background-size: 100%;
    //backdrop-filter: blur(2px) saturate(65%);
    padding-top: 195px;
    position: relative;

    @include media-breakpoint-down(lg) {
        padding-top: 108px;
    }
}

@include media-breakpoint-down(md) {
    .main-navbar {
        z-index: 1020;
        position: sticky !important;
        top: 0;
    }

    body {
        padding-top: 0;
    }

}

.btn {
    font-family: "Helverita Neu Bold", sans-serif;
}

a {
    color: black;
    text-decoration: none;
}

.content-container {
    background-color: rgba(219, 209, 209, 0.95);
}

.bg-main {
    background-color: var(--content-background);
}

#content {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    flex: 1;

    &.bg-enabled {
        background-color: var(--content-background);
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 100%;
        margin: 0 !important;
    }
}

#flex-container {
    display: flex;
    flex-direction: column;

    min-height: calc(100vh - 195px);

    @include media-breakpoint-down(lg) {
        min-height: calc(100vh - 108px);
    }
}


.swiper-button-prev::after, .swiper-button-next::after {
    background-color: lightgray;
    padding: 0.1em 0.4em;
    border-radius: 10px;
}

.payment-container {
    @include media-breakpoint-up(lg) {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;

        :root {
            --content-background: transparent !important;
        }
    }
}