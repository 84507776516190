.browse-categories {
  * {
    font-family: "Palatino Linotype", serif;
  }

  & > * {
    border: 1px solid darkgray;


    &:nth-child(4n+1), &:nth-child(4n+2) {
      background-color: #F0E1E1;
    }

    &:nth-child(4n+3), &:nth-child(4n+4) {
      background-color: #CB7474;
    }
  }

}