.book-preview {
    display: flex;
    flex-direction: column;

    .book-preview-title {
        text-align: center;
    }
}

.book-cover {
    position: relative;
    overflow: hidden;
    height: min-content;

    &.used-banner::after {
        content: 'USED';
        position: absolute;
        width: 200%;
        height: 60px;

        font-size: 25pt;

        bottom: 5%;
        right: -70%;

        transform: rotate(-30deg);

        background-color: #ff33a5;
        //border: 1px solid black;
        //box-shadow: black 15px 0 10px 0;
    }

}

.cart-used-banner::after {
    content: attr(data-used-text);
    position: absolute;
    width: 200%;
    height: 40px;

    text-align: center;

    font-size: 15pt;

    bottom: 0;
    right: -70%;

    transform: rotate(-30deg);

    background-color: #ff33a5;
    //border: 1px solid black;
    //box-shadow: black 15px 0 10px 0;
}


.book-navigation-header {
    font-family: "Palatino Linotype", serif;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.180em;

    align-items: center;

    background-color: #CB7474;

    border: 1px solid #707070;

    .title {
        color: #27175D;
    }

    .title2 {
        color: white;
    }

    img {
        height: 4em;
    }
}

.book-navigation-top, .book-navigation-bottom {
    display: flex;
    flex-direction: row;

    color: #777;

    &.book-navigation-top {
        border-bottom: 1px solid #777;
    }

    &.book-navigation-bottom {
        border-top: 1px solid #777;
    }

    .book-navigation-current {
        color: black;
    }

    a {
        color: inherit;
    }

    :first-child {
        margin-right: auto;
    }
}

.book-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: 0 1.2em;
    grid-template-rows: min-content 230px min-content min-content;
}

.book-list-item {
    text-align: center;
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: span 4;
    gap: 0;
}