nav.main-navbar {
  font-size: 12px !important;
  letter-spacing: 2px;
  background-color: $cf-orange;
  box-shadow: black 0 0 5px;

  * {
    color: #333333 !important;
  }

  .nav-item.active {
    background-color: #ed7d28;
    border-radius: 1em;
  }

  .navbar-grid {
    display: grid !important;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: min-content min-content min-content;

    @include media-breakpoint-down(lg) {
      grid-template-columns: min-content 1fr min-content;
    }

    #navbar {
      grid-column: 2/3;
      grid-row: 3;

      font-size: 16px;

      justify-self: center;
      align-self: end;

      @include media-breakpoint-down(lg) {
        grid-column: 1/4;
        justify-self: unset;
      }
    }

    .navbar-search {
      grid-column: 2/3;
      grid-row: 1;

      @include media-breakpoint-down(lg) {
        grid-column: 2;
      }
    }

    .navbar-social {
      grid-column: 3/4;
      grid-row: 1;
    }

    .navbar-search-text {
      grid-column: 2;
      grid-row: 2;

      @include media-breakpoint-down(lg) {
        grid-column: 1/3;
      }
    }

    .navbar-brand {
      grid-row: 1/4;
    }

    .navbar-cart {
      grid-column: 3;
      grid-row: 3;
      justify-self: center;

      @include media-breakpoint-up(sm) {
        margin-right: 1em;
      }

      @include media-breakpoint-down(md) {
        grid-row: 1/3;
      }
    }
  }
}


footer.footer {
  //display: grid;
  grid-template-columns: 1fr 1fr 3fr 1fr 1fr;
  font-size: 10pt;
  letter-spacing: 1px;
  background-color: $cf-orange;
  font-family: Arial, sans-serif;

  color: #707070;

  div {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-down(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 0.75em;

    .footer-address {
      grid-column: 1/3;
      grid-row: 3;
    }

    div {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
}

.disclaimer {
  background-color: red !important;
  color: white !important;
  font-family: sans-serif !important;
  padding: 10px;
}